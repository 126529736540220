// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_TkgZr{min-height:43rem}.table_D2OXO{min-width:40rem}.table_D2OXO.loading_JhCRT{height:auto}.loaderContainer_yqZ9j{align-items:center;display:flex;flex:1;justify-content:center}.loader_8rsLA{height:auto;width:auto}.loader_8rsLA,.loader_8rsLA *{position:static}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_TkgZr",
	"table": "table_D2OXO",
	"loading": "loading_JhCRT",
	"loaderContainer": "loaderContainer_yqZ9j",
	"loader": "loader_8rsLA"
};
module.exports = ___CSS_LOADER_EXPORT___;
