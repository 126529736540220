// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_FU7J2{display:flex;flex-direction:row}.label_SoRiN{display:flex;margin:0 2rem;transform:translateY(.1rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_FU7J2",
	"label": "label_SoRiN"
};
module.exports = ___CSS_LOADER_EXPORT___;
