// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_LDtqj{display:flex;flex-direction:column;height:80vh;overflow:hidden;padding:1rem;width:90dvw}.row_wM11V{display:flex;flex-direction:row}.grid_UollP{grid-gap:5rem;display:grid;gap:5rem;grid-template-columns:auto 1fr;overflow:hidden}.seriesContainer_MI1bZ{display:flex;flex-direction:column;gap:.5rem;height:100%;min-height:0;overflow:auto}button[disabled]{opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_LDtqj",
	"row": "row_wM11V",
	"grid": "grid_UollP",
	"seriesContainer": "seriesContainer_MI1bZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
